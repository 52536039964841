import PropTypes from 'prop-types'
import styled from 'styled-components';
import React from 'react'

const SectionBlock = styled('section')`
  width: 100vw;
  height: 100vh;
  position: relative;
  background: #000;
  overflow: hidden;
  padding: 0 calc(20px + (200 - 20) * (((var(--text-scale-unit) * 100) - 320px) / (1600 - 320)));
  @media (max-width: 1024px) {
    padding: 0 calc(20px + (50 - 20) * (((var(--text-scale-unit) * 100) - 320px) / (1024 - 320)));
  }
  @media (max-width: 768px) {
    padding: 0 calc(20px + (20 - 20) * (((var(--text-scale-unit) * 100) - 320px) / (768 - 320)));
  }
`;

const Section = ({children, style, id}) => (
  <SectionBlock
    style={style}
    id={id}
  >
    {children}
  </SectionBlock>
)

Section.propTypes = {
  children: PropTypes.node,
  style: PropTypes.instanceOf(Object),
  id: PropTypes.string
}

export default Section
